<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card elevation="2">
          <v-toolbar dark color="teal darken-2" elevation="0">
            <v-toolbar-title class="caption">{{
              "Usuários do Sistema" | uppercase
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            <v-btn
              color="teal lighten-1"
              rounded
              small
              class="sm-2 white--text"
              @click="viewModal()"
            >
              <v-icon small left>mdi-plus-circle</v-icon>
              Novo Usuário
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headerProps"
            :items="listStaff"
            :search="search"
            class="elevation-1"
            :footer-props="footerProps"
            loading="isLoading"
            loading-text="Carregando..."
          >
            <template v-slot:[`item.users_name`]="{ item }">
              {{ item.users_name | uppercase }}
            </template>
            <template v-slot:[`item.users_mail`]="{ item }">
              {{ item.users_mail | uppercase }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn color="primary" rounded x-small class="sm-2 white--text">
                <v-icon x-small left>mdi-eye</v-icon>
                Visualizar
              </v-btn>
              <v-btn
                color="red"
                rounded
                x-small
                class="sm-2 white--text"
                @click="viewModal('update', item)"
              >
                <v-icon x-small left> mdi-pencil </v-icon>
                Editar
              </v-btn>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
    <v-dialog v-model="dialog" persistent scrollable max-width="650px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class=""
            >{{ modalTitle }} - #{{ parseInt(staff.id * 100) }}</v-toolbar-title
          >
        </v-toolbar>
        <!-- <v-card-title>
          <span class="headline">{{ modalTitle }}</span>
        </v-card-title> -->
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    dense
                    v-model="staff.name"
                    label="Nome: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="staff.cpf"
                    label="CPF: *"
                    v-mask="'###.###.###-##'"
                    :rules="cpfRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="staff.birthDate"
                    label="Nascimento: *"
                    :rules="birthRules"
                    required
                    v-mask="'##/##/####'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    dense
                    v-model="staff.email"
                    label="E-mail: *"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    label="Celular: *"
                    v-mask="'(##) #####-####'"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                    v-model="staff.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    dense
                    v-model="staff.accessLevel"
                    :items="[
                      { id: '1', name: 'Usuário' },
                      { id: '10', name: 'Administrador' },
                    ]"
                    label="Nível de Acesso: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    item-value="id"
                    item-text="name"
                    value="1"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="modalType == 'insert'">
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="staff.login"
                    label="Usuario: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="staff.passwd"
                    label="Senha: *"
                    :rules="passRules"
                    required
                    :append-icon="eyepass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (eyepass = !eyepass)"
                    :type="eyepass ? 'password' : 'text'"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="staff.repasswd"
                    label="Re-Senha: *"
                    :rules="repassRules"
                    required
                    :append-icon="eyepass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (eyepass = !eyepass)"
                    :type="eyepass ? 'password' : 'text'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="modalType == 'update'">
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="staff.login"
                    label="Usuario: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense label="Senha: " disabled></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    label="Re-Senha: "
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertConta()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateConta()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  filters: {
    uppercase: function (text) {
      return text.toUpperCase();
    },
    dateFormat: function (date) {
      return this.moment(date, "DD/MM/YYYY T00:00:00");
    },
  },
  data: () => ({
    configHeader: {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        //'Authorization': `Bearer 1234`
      },
    },
    isLoading: false,
    overlay: true,
    drawer: null,
    dialog: false,
    modalTitle: "",
    modalType: "",
    modalBtnCancel: "Cancelar",
    valid: true,
    search: "",
    eyepass: true,
    headerProps: [
      {
        text: "Nome",
        sortable: true,
        value: "users_name",
      },
      {
        text: "E-mail",
        sortable: true,
        value: "users_mail",
      },
      {
        text: "Telefone",
        sortable: false,
        value: "users_phone",
      },
      {
        text: "Opções",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    footerProps: {
      "items-per-page-text": "Registros por página",
      "items-per-page-options": [10, 20, 30, 40, 50],
      pageText: "{0}-{1} de {2}",
      // 'disable-items-per-page': false
    },
    listStaff: [],
    staff: {
      id: "",
      name: "",
      login: "",
      passwd: null,
      repasswd: null,
      email: "",
      phone: "",
      cpf: "",
      birthDate: "",
      accessLevel: "",
    },

    cpfRules: [
      (v) => !!v || "Campo obrigatório",
      (v) => !!this.validateCpf(v) || "CPF inválido",
    ],
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    birthRules: [
      (v) => !!v || "Data de Nascimento é obrigatória",
      (v) =>
        !!(
          /^\d{2}\/\d{2}\/\d{4}$/.test(v) &&
          new Date(this.moment(v, "DD/MM/YYYY T00:00:00")).getTime()
        ) || "Data inválida",
    ],
    passRules: [
      (v) => !!v || "Informe uma senha",
      (v) => (v && v.length >= 8) || "Deve ter pelo menos 8 caracteres",
      (v) => /(?=.*[A-Za-z])/.test(v) || "Ao menos uma letra",
      (v) => /(?=.*\d)/.test(v) || "Ao menos um caracter Numérico",
    ],
    repassRules: [
      (v) => !!v || "Digite a senha novamente",
      (v) => v === this.staff.passwd || "A senha não confere",
    ],
  }),
  methods: {
    toggleOverlay() {
      this.overlay = !this.overlay;
    },
    reset() {
      this.dialog = false;
      this.$refs.form.reset();
      // this.staff.phone = null
      this.$refs.form.resetValidation();
    },
    validateCpf: (cpf) => {
      if (typeof cpf !== "string") return false;
      cpf = cpf.replace(/[^\d]+/g, "");
      if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
      cpf = cpf.split("");
      const validator = cpf
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        .map((el) => +el);
      const toValidate = (pop) =>
        cpf
          .filter((digit, index, array) => index < array.length - pop && digit)
          .map((el) => +el);
      const rest = (count, pop) =>
        ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
          10) %
          11) %
        10;
      return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
    },
    getStaff() {
      //   let vm = this;
      //   this.isLoading = true;

      //   await this.axios
      //     .get("/controllers/Staff.php?f=l", vm.configHeader)
      //     .then(function (response) {
      //       if (response.data.success) {
      //         this.listStaff = response.data.data;
      //         this.isLoading = false;
      //       }
      //     })
      //     .catch(async function (error) {
      //       console.log(error);
      //       this.isLoading = false;
      //       this.Swal.mixin({
      //         toast: true,
      //         position: "bottom-end",
      //         showConfirmButton: false,
      //         timer: 5000,
      //       }).fire({
      //         icon: "error",
      //         title: "Ocorreu um erro.",
      //       });
      //     });
      this.isLoading = false;
    },
    async insertStaff() {
      let vm = this;
      if (vm.$refs.form.validate()) {
        vm.isLoading = true;
        const params = new URLSearchParams();
        params.append("name", vm.staff.name);
        params.append("cpf", vm.staff.cpf);
        params.append("docCIC", vm.staff.username);
        params.append("birthDate", vm.staff.birthDate);
        params.append("gender", vm.staff.pass);

        await this.axios
          .post("/controllers/Staff.php", params, vm.headers)
          .then(function (response) {
            vm.isLoading = false;
            if (response.data.success) {
              this.Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 5000,
              }).fire({
                icon: "success",
                title: response.data.message,
              });
              vm.getStaff();
              vm.reset();
            } else {
              this.Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 5000,
              }).fire({
                icon: "error",
                title: response.data.message,
              });
            }
          })
          .catch(function () {
            vm.isLoading = false;
            this.Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 5000,
            }).fire({
              icon: "error",
              title: "Erro Inesperado.",
            });
          });
      }
    },
    async updateStaff() {
      let vm = this;
      vm.isLoading = true;
      vm.swal
        .fire({
          title: "Deseja atualizar este registro?",
          text: "Atualizar dados do representante.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async function (result) {
          if (result.value) {
            const params = new URLSearchParams();

            params.append("id", vm.staff.id);
            params.append("name", vm.staff.name);
            params.append("cpf", vm.staff.cpf);
            params.append("docCIC", vm.staff.username);
            params.append("birthDate", vm.staff.birthDate);
            params.append("gender", vm.staff.pass);

            await vm.axios
              .post("/controllers/Staff.php?f=u", params, {
                "content-type": "application/x-www-form-urlencoded",
              })
              .then(function (response) {
                vm.isLoading = false;
                if (response.data.success) {
                  vm.swal.fire(
                    "Atualizado!",
                    "Os dados foram atualizados com sucesso.",
                    "success"
                  );
                  vm.getStaff();
                  vm.modalBtnCancel = "Sair";
                } else {
                  vm.swal.fire("Falha!", response.data.message, "warning");
                }
              })
              .catch(function () {
                vm.isLoading = false;
                vm.Swal.mixin({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 5000,
                }).fire({
                  icon: "error",
                  title: "Erro Inesperado.",
                });
              });
          }
        });
    },
    viewModal(type = "insert", staffs = "null") {
      this.modalBtnCancel = "Cancelar";
      if (type === "update") {
        this.modalTitle = "Editar Funcionário";
        this.staff.id = staffs.users_id;
        this.staff.name = staffs.users_name;
        this.staff.cpf = staffs.users_cpf;
        this.staff.login = staffs.users_login;
        this.staff.birthDate = staffs.users_birthdate;
        // this.staff.phone = staffs.users_phone.replace(/[^\d]+/g, '')
        this.staff.phone = staffs.users_phone;
        this.staff.email = staffs.users_mail;
        this.staff.accessLevel = staffs.users_accessLevel;
      } else {
        this.modalTitle = "Cadastrar Funcionário";
      }
      this.modalType = type;
      this.dialog = true;
    },
  },
  created: function () {},
  mounted: function () {
    this.getStaff();
    this.toggleOverlay();
  },
  computed: {},
};
</script>