<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card elevation="2">
          <v-toolbar dark color="teal darken-2" elevation="0">
            <v-toolbar-title class="caption"
              >Ficha Pessoa Jurídica</v-toolbar-title
            >
          </v-toolbar>
          <v-card-title>
            <v-btn
              color="teal lighten-1"
              rounded
              small
              class="sm-2 white--text"
              @click="viewModal()"
            >
              <v-icon small left>mdi-plus-circle</v-icon>
              Novo Cliente
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headerProps"
            :items="listClients"
            :search="search"
            class="elevation-1"
            :footer-props="footerProps"
            :loading="isLoading"
            loading-text="Carregando..."
          >
            <template v-slot:[`item.client_name`]="{ item }">
              {{ item.client_name | uppercase }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-btn color="primary" rounded x-small class="sm-2 white--text">
                <v-icon x-small left>mdi-eye</v-icon>
                Visualizar
              </v-btn> -->
              <v-btn
                color="red accent-3"
                rounded
                x-small
                class="sm-2 white--text"
                @click="viewModal('update', item)"
              >
                <v-icon x-small left> mdi-pencil </v-icon>
                Editar
              </v-btn>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">{{ modalTitle }} - #{{parseInt(client.id*100)}}</v-toolbar-title>
        </v-toolbar>
        <!-- <v-card-title>
          <span class="headline">{{ modalTitle }}</span>
        </v-card-title> -->
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="client.name"
                    label="Razão Social: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="client.cnpj"
                    label="CNPJ: *"
                    v-mask="'##.###.###-####/##'"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="client.birthDate"
                    label="Data Abertura: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                    v-mask="'##/##/####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="client.docCIC"
                    label="IE:"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="client.gender"
                    :items="[
                      { id: 'S', name: 'Optante' },
                      { id: 'N', name: 'Não Optante' },
                    ]"
                    label="Simples: "
                    item-value="id"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertClient()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateClient()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  filters: {
    uppercase: function (text) {
      return text.toUpperCase();
    },
    dateFormat: function (date) {
      return this.moment(date).format("DD/MM/YYYY");
    },
  },
  data: () => ({
    isLoading: true,
    overlay: true,
    drawer: null,
    dialog: false,
    modalTitle: "",
    modalType: "",
    modalBtnCancel: "Cancelar",
    valid: true,
    search: "",
    headerProps: [
      {
        text: "Razão Social",
        sortable: true,
        value: "client_name",
      },
      {
        text: "CNPJ",
        sortable: true,
        value: "client_cnpj",
      },
      {
        text: "Cadastrado",
        sortable: false,
        value: "client_birthDate",
      },
      {
        text: "Opções",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    footerProps: {
      "items-per-page-text": "Registros por página",
      "items-per-page-options": [10, 20, 30, 40, 50],
      pageText: "{0}-{1} de {2}",
      // 'disable-items-per-page': false
    },
    listClients: [
      {
        client_id: Math.random(),
        client_name: "Cliente Teste",
        client_cnpj: "11.111.111-0001/11",
        client_cic: "123456",
        client_birthDate: "12/12/1990",
        client_sex: "M",
      },
      {
        client_id: Math.random(),
        client_name: "Cliente Teste 2",
        client_cnpj: "22.222.222-0001/11",
        client_cic: "123456",
        client_birthDate: "12/12/1992",
        client_sex: "M",
      },
    ],
    client: {
      id: "",
      name: "",
      cnpj: "",
      docCIC: "",
      birthDate: "",
      gender: "",
    },
  }),
  methods: {
    toggleOverlay() {
      this.overlay = !this.overlay;
    },
    reset() {
      this.dialog = false;
      // this.client.id = "";
      // this.client.name = "";
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    async getClients() {
      // let vm = this
      // vm.isLoading = true;
      // console.log("dentro do get");
      // await vm.api
      //   .get("/controllers/Clients.php?f=l")
      //   .then(function (response) {
      //     if (response.data.success) {
      //       // console.table(response.data.data)
      //       vm.listClients = response.data.data;
      //     }
      //   })
      //   .catch(function () {

      // vm.$swal
      //   .mixin({
      //     toast: true,
      //     position: "bottom-end",
      //     showConfirmButton: false,
      //     timer: 5000,
      //   })
      //   .fire({
      //     icon: "error",
      //     title: "Não há dados há exibir.",
      //   });
      //   });
      this.isLoading = false;
    },
    insertClient() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let new_client = {
          client_id: Math.random(),
          client_name: this.client.name,
          client_cnpj: this.client.cnpj,
          client_cic: this.client.docCIC,
          client_birthDate: this.client.birthDate,
          client_sex: this.client.gender,
        };
        this.listClients.push(new_client);
        this.isLoading = false;
        this.reset();
      }
      // await this.axios
      //   .post("/controllers/Clients.php?f=i", params, {
      //     "content-type": "application/x-www-form-urlencoded",
      //   })
      //   .then(function (response) {
      //     this.isLoading = false;
      //     if (response.data.success) {
      //       this.Swal.mixin({
      //         toast: true,
      //         position: "bottom-end",
      //         showConfirmButton: false,
      //         timer: 5000,
      //       }).fire({
      //         icon: "success",
      //         title: response.data.message,
      //       });
      //       this.getClients();
      //       this.reset();
      //     } else {
      //       this.Swal.mixin({
      //         toast: true,
      //         position: "bottom-end",
      //         showConfirmButton: false,
      //         timer: 5000,
      //       }).fire({
      //         icon: "error",
      //         title: response.data.message,
      //       });
      //     }
      //   })
      //   .catch(function () {
      //     this.isLoading = false;
      //     this.Swal.mixin({
      //       toast: true,
      //       position: "bottom-end",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     }).fire({
      //       icon: "error",
      //       title: "Erro Inesperado.",
      //     });
      //   });
    },
    updateClient() {
      //this.isLoading = true;
      this.$swal
        .fire({
          title: "Deseja atualizar este registro?",
          text: "Não é possível desfazer esta ação.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(function () {
          // if (result.value) {
          //   const params = new URLSearchParams();
          //   params.append("id", this.client.id);
          //   params.append("name", this.client.name);
          //   params.append("cpf", this.client.cpf);
          //   params.append("docCIC", this.client.docCIC);
          //   params.append("birthDate", this.client.birthDate);
          //   params.append("gender", this.client.gender);
          //   await this.axios
          //     .post("/controllers/Clients.php?f=u", params, {
          //       "content-type": "application/x-www-form-urlencoded",
          //     })
          //     .then(function (response) {
          //       this.isLoading = false;
          //       if (response.data.success) {
          //         this.swal.fire(
          //           "Atualizado!",
          //           "Os dados foram atualizados com sucesso.",
          //           "success"
          //         );
          //         this.getClients();
          //         this.modalBtnCancel = "Sair";
          //       } else {
          //         this.swal.fire("Falha!", response.data.message, "warning");
          //       }
          //     })
          //     .catch(function () {
          //       this.isLoading = false;
          //       this.Swal.mixin({
          //         toast: true,
          //         position: "bottom-end",
          //         showConfirmButton: false,
          //         timer: 5000,
          //       }).fire({
          //         icon: "error",
          //         title: "Erro Inesperado.",
          //       });
          //     });
          //}
        });
      this.reset();
    },
    viewModal(type = "insert", client = "null") {
      this.modalBtnCancel = "Cancelar";
      if (type === "update") {
        console.log(client);
        this.modalTitle = "Editar Cliente";
        this.client.id = client.client_id;
        this.client.name = client.client_name;
        this.client.cpf = client.client_cpf;
        this.client.docCIC = client.client_docCIC;
        this.client.birthDate = client.client_birthDate;
        this.client.gender = client.client_sex;
      } else {
        this.modalTitle = "Cadastrar Cliente";
        this.client.id = 1;
      }
      
      this.modalType = type;
      this.dialog = true;
    },
  },
  created: function () {},
  mounted: function () {
    this.getClients();
    this.toggleOverlay();
  },
};
</script>