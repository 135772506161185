import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import swal from 'vue-sweetalert2'
import api from './plugins/Api'
import VueTheMask from 'vue-the-mask'
import VueMoment from 'vue-moment'
import money from 'v-money'


import 'sweetalert2/dist/sweetalert2.min.css';
import './styles/base.css'


Vue.use(swal);
Vue.use(VueTheMask)
Vue.use(VueMoment)
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
})

Vue.prototype.api = api

Vue.config.productionTip = false


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')