<template>
   <v-app id="inspire">
      <v-content>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Card Plus</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form ref="login" lazy-validation>
                           <v-text-field
                              prepend-icon="mdi-account"
                              name="login"
                              label="Login"
                              type="text"
                              v-model="user"
                              required
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              label="Password"
                              type="password"
                              v-model="pass"
                              required
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="logar">Entrar</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-content>
   </v-app>
</template>

<script>
export default {
   name: 'Login',
   props: {
      source: String,
   },
   data: () => ({
      user:'',
      pass:''
   }),
   methods: {
      logar (){
         if(this.user==='admincardplus' && this.pass==='admin'){
            localStorage.setItem('cardplustoken',true)
         this.$router.replace('dashboard')
         }else{
            this.$refs.login.validate()
         }
         
      },
      logout (){
         localStorage.removeItem('cardplustoken')
      }
   }
};
</script>

<style></style>
