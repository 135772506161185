import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ClientPF from '../views/ClientPF.vue'
import ClientPJ from '../views/ClientPJ.vue'
import ContasPagar from '../views/ContasPagar.vue'
import ContasReceber from '../views/ContasReceber.vue'
import Usuarios from '../views/Usuarios.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '*',
    component: Login,
  }, {
    path: '/',
    name: 'Login',
    component: Login,
  },{
    path: '/dashboard',
    name: 'Home',
    component: Home,
    meta: {
      navBarEnable: true,
      requiresAuth:true
    }
  },
  {
    path: '/clientpf',
    name: 'ClientPF',
    component: ClientPF,
    meta: {
      navBarEnable: true,
      requiresAuth:true
    }
  },
  {
    path: '/clientpj',
    name: 'ClientPJ',
    component: ClientPJ,
    meta: {
      navBarEnable: true,
      requiresAuth:true
    }
  },
  {
    path: '/contas-a-pagar',
    name: 'ContaPagar',
    component: ContasPagar,
    meta: {
      navBarEnable: true,
      requiresAuth:true
    }
  },
  {
    path: '/contas-a-receber',
    name: 'ContaReceber',
    component: ContasReceber,
    meta: {
      navBarEnable: true,
      requiresAuth:true
    }
  },
  {
    path: '/acesso',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
      navBarEnable: true,
      requiresAuth:true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next)=>{
  const Auth = localStorage.getItem('cardplustoken');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if(requiresAuth && !Auth) next('Login');
  else if (!requiresAuth && Auth) next('Home');
  else next();
})

export default router
